import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth-service/auth.service';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private router: Router
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authService = this.injector.get(AuthService);
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authService.getToken()}`
      }
    })
    return next.handle(tokenizedReq).pipe(
      catchError(
        (err, caught) => {
          if (err.status === 401) { // unauthorized
            this.handleAuthError();
            return of(err)
          }
          if (err?.error?.status_code === 500 && err?.error?.message === 'jwt expired') { // unauthorized
            this.handleAuthError();
            return of(err)
          }
          throw err;
        }
      )
    )
  }
  private handleAuthError() {
    localStorage.clear()
    this.router.navigate([''])
  }
}
