<div class="row">
    <div class="col-md-12">
        <h1 class="text-success">Payment Successful!</h1>
        <p class="text-md-left">Your payment has been processed successfully.</p>

        <button class="btn-success" (click)="onSubmit()">
            OK
        </button>
    </div>
</div>
