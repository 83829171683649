import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../Utils/utility.service';

const AUTH_API = environment.serverURL;
@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient, private utilityService: UtilityService
  ) { }
  getAllAdmins(payload) {
    return this.http.post<any>(`${AUTH_API}/admin/get-module-admin-list`, payload).pipe(map(result => {

      if (result.success) {
        return {
          data: result.data.map(ele => {
            return {
              ...ele,
              name: ele.firstName + ' ' + ele.lastName,
              role: ele.roleInfo.roleName,

              createdAt_Local: this.utilityService.formatDate(ele.updatedAt_Local ? ele.updatedAt_Local : ele.createdAt_Local),

            }
          }), totalCount: result.totalCount
        }

      }
    }))
  }
  addAdmin(payload) {
    return this.http.post(`${AUTH_API}/admin/add-module-admin`, payload)
  }
  updateAdmin(payload) {
    return this.http.post(`${AUTH_API}/admin/update-module-admin`, payload)
  }
  updateSuperAdmin(payload) {
    return this.http.post(`${AUTH_API}/admin/update-super-admin`, payload)
  }
  getRoles(payload) {
    return this.http.post(`${AUTH_API}/admin/get-roles`, payload)
  }
  getAdmin(payload) {
    return this.http.post(`${AUTH_API}/admin/get-admin-details`, payload)
  }
  statusToggleSuperAdmin(payload) {
    return this.http.post(`${AUTH_API}/admin/status-toggle`, payload)
  }

  // SPA (service provider) user
  getAllSPAdmins(payload) {
    return this.http.post<any>(`${AUTH_API}/serviceProviderAdmin/get-module-admin-list`, payload).pipe(map(result => {

      if (result.success) {
        return {
          data: result.data.map(ele => {
            return {
              ...ele,
              name: ele?.firstName + ' ' + ele?.lastName,
              role: ele?.roleInfo?.roleName,
              // createdAt_Local: moment(ele.createdAt_Local).format('DD/MM/YYYY hh:mm a'),
              createdAt_Local: this.utilityService.formatDate(ele?.createdAt_Local),
            }
          }), totalCount: result?.totalCount
        }
      }
    }))
  }
  addSPAdmin(payload) {
    return this.http.post(`${AUTH_API}/serviceProviderAdmin/add-module-admin`, payload)
  }
  updateSPAdmin(payload) {
    return this.http.post(`${AUTH_API}/serviceProviderAdmin/update-module-admin`, payload)
  }
  getRolesSPA(payload) {
    return this.http.post(`${AUTH_API}/serviceProviderAdmin/get-dropdown-roles`, payload)
  }
  getSPAdmin(payload) {
    return this.http.post(`${AUTH_API}/serviceProviderAdmin/get-admin-details`, payload)
  }
  statusSPAdminToggle(payload) {
    return this.http.post(`${AUTH_API}/serviceProviderAdmin/module-admin-status-toggle`, payload)
  }

}
