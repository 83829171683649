import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';

const AUTH_API = environment.serverURL;
@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket: any;
  private socketConnectionInstance = new BehaviorSubject<any>({});
  private newMessage = new BehaviorSubject<any>({});
  socketConnectionInstanceObservable = this.socketConnectionInstance.asObservable();
  newMessageObservable = this.newMessage.asObservable();
  private deactivate = new BehaviorSubject<any>({})
  _onDeactivate = this.deactivate.asObservable()
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  setupSocketConnection() {
    const token = this.auth.getToken();
    this.socket = io(environment.SOCKET_ENDPOINT, {
      query: { token }
    });
    this.sendSocketActiveInstance(this.socket);
  }

  sendSocketActiveInstance(data) {
    this.socketConnectionInstance.next(data);
  }

  getSocketInstance() {
    let Obj = {
      socket: this.socket,
      myMessage: this.mymessage()
    }
    return Obj;

  }

  mymessage() {
    this.socket.emit('typing', "Here is typing event ...");
  }

  createChatRoom(payload: any): Observable<any> {
    return this.http.post(AUTH_API + '/serviceProviderAdmin/create-chat-room', payload);
  }
  getChatRoom(payload: any): Observable<any> {
    return this.http.post(AUTH_API + '/serviceProviderAdmin/get-chat-room', payload);
  }

  joinAdminChatRoom(data: any) {
    this.socket.emit('joinadminchat', data);
  }

  getAdminChatsList(payload: any): Observable<any> {
    return this.http.post(AUTH_API + '/serviceProviderAdmin/get-admin-chat', payload);
  }

  sendMessageStaff(payload: any) {
    this.socket.emit('admin-message', payload);
  }

  newMessageArrive() {
    this.socket.on('new-admin-message', (message) => {
      this.newMessage.next(message);
    })
  }
  //
  userChannel(userId) {
    if (this.socket) {
      this.socket.emit('user-channel', userId)
    }
  }
  deactivateUser(user) {
    // console.log('user', user);

    this.socket.emit('deactivate-user', user)
  }
  setDeactivateUser() {
    if (this.socket) {
      // console.log('Auto logout');

      this.socket.on('check-deactive', (user) => {
        this.deactivate.next(user)
      })
      return this.deactivate.asObservable()
    }
    return
  }

}
