import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { UtilityService } from "../services/Utils/utility.service";
// import { } from 'googlemaps';
@Component({
    selector: 'AutocompleteComponent',
    templateUrl: './google-places.component.html',
    styleUrls: ['./google-places.component.scss']
})
export class GooglePlacesComponent implements OnInit {

    @ViewChild("placesRef") placesRef: GooglePlaceDirective;
    @Input('defaultValue') defaultValue: string = '';
    @Input() addressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addressText') addressText: any;
    constructor(private utils: UtilityService) {
    }
    autocompleteInput: string;
    queryWait: boolean;

    options = {
        // componentRestrictions: {
        //     country: ["IN"],
        // }
    }
    private lat: any;
    private lng: any;
    public handleAddressChange(address) {
        let addressData: any = this.utils.getClientCountryStateAndCity(address.address_components);
        this.lat = address.geometry.location.lat();
        this.lng = address.geometry.location.lng();
        let data = {
            address: address.formatted_address,
            latitude: this.lat,
            longitude: this.lng,
            country: addressData.country,
            state: addressData.state,
            city: addressData.city,
        }
        this.invokeEvent(data);
    }



    ngOnInit() {
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

}
