<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem?.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem?.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem?.extralink === true">
                <i [ngClass]="[sidebarnavItem?.icon]">
                </i>
                <span class="hide-menu">
                    {{sidebarnavItem?.title}}
                </span>
            </div>
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                *ngIf="!sidebarnavItem.extralink;" [ngClass]="[sidebarnavItem?.class]"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">

                <img [src]="sidebarnavItem.icon">
                <span class="hide-menu leftmargin" style="margin-left: 10px;">{{sidebarnavItem.title }}
                    <span *ngIf="sidebarnavItem.label != '' " [ngClass]="">{{sidebarnavItem.label }}</span>
                </span>
            </a>
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem?.title }">
                <li class="sidebar-item" style="margin-left: 10px;"
                    *ngFor="let sidebarnavSubItem of sidebarnavItem?.submenu"
                    (click)="sidebarnavSubItem?.submenu?.length != 0 ? '' : handleNotify()"
                    [class.active]="showSubMenu === sidebarnavSubItem?.title"
                    [routerLinkActive]="sidebarnavSubItem?.submenu?.length > 0 ? '' : 'active'">
                    <a class="sidebar-link" style="font-size: 14px;"
                        [routerLink]="sidebarnavSubItem?.submenu?.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem?.submenu?.length > 0 ? '' : 'active'"
                        *ngIf="!sidebarnavSubItem?.extralink;" (click)="addActiveClass(sidebarnavSubItem?.title)">
                        <img [src]="sidebarnavSubItem?.icon">
                        <span class="hide-menu leftmargin" style="margin-left: 10px;">
                            {{sidebarnavSubItem?.title}}</span>
                    </a>
                    <ul aria-expanded="false" class="collapse Second-level"
                        *ngIf="sidebarnavSubItem?.submenu?.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem?.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem?.submenu"
                            [routerLinkActive]="sidebarnavSubsubItem?.submenu?.length > 0 ? '' : 'active'"
                            [ngClass]="[sidebarnavSubsubItem?.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem?.path]"
                                *ngIf="!sidebarnavSubsubItem?.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem?.submenu?.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem?.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem?.title }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <!-- <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let item of sidebarnavItems">
            <span>{{item.title}}</span>
        </li> -->
    </ul>
</nav>