import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth-service/auth.service";
import { VerticalSidebarService } from "./vertical-sidebar.service";
declare var $: any;

@Component({
  selector: "app-vertical-sidebar",
  templateUrl: "./vertical-sidebar.component.html",
  styleUrls: ["./vertical-sidebar.component.scss"],
})
export class VerticalSidebarComponent implements OnInit {
  showMenu = "";
  showSubMenu = "";
  public sidebarnavItems: any[] = [];
  path = "";
  //asd

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleNotify() {
    this.notify.emit(!this.showClass);
  }

  constructor(
    private menuServise: VerticalSidebarService,
    private router: Router,
    private authService: AuthService
  ) {
    this.sidebarnavItems = [];
    this.menuServise.items.subscribe((menuItems) => {
      // this.sidebarnavItems = menuItems;
      // console.log('sidebarnavItems', this.sidebarnavItems);
      this.sidebarnavItems = this.authService.getAccessModules();
      // this.sidebarnavItems.forEach(item => {
      //   item.submenu.forEach((subItem, idx) => {
      //     if(subItem.path === '/master-collection/taxes-charges') {
      //       item.submenu.splice(idx, 1)
      //     }
      //   })
      // })

      // Active menu
      this.sidebarnavItems?.filter((m) =>
        m.submenu.filter((s) => {
          if (s.path === this.router.url) {
            this.path = m.title;
            // // console.log('selected : ', m, '\npath : ', this.path);
            this.addExpandClass(this.path);
          }
        })
      );
    });
  }
  ngOnInit(): void {
    console.log("this.sidebarnavItems", this.sidebarnavItems);
    const route_path = this.router.url.split("/")[1];
    // this.sidebarnavItems.filter(m => m.submenu.filter(
    //   (s) => {
    //     // console.log('\n sidebarnavItems', s.path === route_path);
    //     if (s.path === route_path) {
    //       this.path = m.title;
    //       // console.log('=========================================================================================== ', this.path);
    //       this.showMenu = this.path;
    //     }
    //   }
    // ));
    const indexSelected: any = this.sidebarnavItems.findIndex(
      (x) => x.path === route_path
    );
    // console.log('index', indexSelected, 'typeof ', typeof indexSelected);

    if (indexSelected != -1) {
      // console.log('indexSelected', this.sidebarnavItems[indexSelected]);
      this.showMenu = this.sidebarnavItems[indexSelected]?.title
        ? this.sidebarnavItems[indexSelected]?.title
        : "";
    }
  }
  addExpandClass(element: any) {
    // console.log('element', element);

    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = "0";
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  logout() {
    this.authService.logout({});
    this.menuServise.items.next([]);

    this.router.navigate([""]);
  }
}
