<router-outlet>
    <!-- <app-spinner></app-spinner> -->

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-pulse" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

</router-outlet>
<!-- {{ message | async | json }} -->

<!-- test  -->
<!--<input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>-->