import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ExportToCsv } from 'export-to-csv';
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";

declare var Checkout: any;
interface PaymentData {
    sessionId: string,
    amount: string,
    currency: string,
    description: string,
    orderId: string,
}
interface ExportToCsvInterface {
    filename: string;
    fieldSeparator: string;
    quoteStrings: string;
    decimalSeparator: string;
    showLabels: boolean;
    showTitle: boolean;
    title: string;
    useTextFile: boolean;
    useBom: boolean;
    useKeysAsHeaders: boolean;
    headers: string[]
};
@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    dynamicTableSubject$ = new Subject(); // this subject will feed dynamic data to common table component
    options: ExportToCsvInterface;

    city;

    state;
    country;
    public MAX_LENGTH_CATEGORY: number = 25;

    private clearFilterValue = new BehaviorSubject({});
    public _clearFilterObserver = this.clearFilterValue.asObservable();
    private afterClearFilterValue = new BehaviorSubject({});
    public _afterClearFilterObserver = this.afterClearFilterValue.asObservable();

    private clearFilterValueStaff = new BehaviorSubject({});
    public _clearFilterObserverStaff = this.clearFilterValueStaff.asObservable();

    //  *** page number ****
    private pageNumber = new BehaviorSubject({});
    public _pageNumberObservable = this.pageNumber.asObservable();
    public direction = new BehaviorSubject('ltr');

    constructor(private http: HttpClient,
        private toastr: ToastrService) {
    }

    getClientCountryStateAndCity(addressArray) {
        addressArray.forEach(element => {
            element.types.forEach(subElement => {
                if (subElement == 'locality') {
                    this.city = element.long_name
                } else if (subElement == 'administrative_area_level_1') {
                    this.state = element.long_name
                } else if (subElement == 'country') {
                    this.country = element.long_name
                }
            });
        });

        return {
            city: this.city,
            state: this.state,
            country: this.country
        };
    }

    formatDate(date) {
        let temp = moment(date).utc().format('DD/MM/YYYY, hh:mm a');


        return temp
    }

    //This function first get lat long of the client and according to that latLong returns an object of country state and city
    getLocation() {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            navigator.geolocation.getCurrentPosition(async resp => {
                const latLong = {
                    lat: parseFloat(String(resp.coords.latitude)),
                    lng: parseFloat(String(resp.coords.longitude)),
                };
                geocoder.geocode({ 'location': latLong }, async (response: any) => {
                    if (response.length > 0) {
                        let data = await this.getClientCountryStateAndCity(response[0].address_components)
                        resolve(data);
                    } else {
                        window.alert("Unable to find Country for current location");
                        reject()
                    }

                })
            });

        });

    }


    getCategoryNameMaxLength() {
        return new Promise((resolve, reject) => {

            resolve(this.MAX_LENGTH_CATEGORY);
        })
    }

    setClearFilterValue(data) {
        this.clearFilterValue.next(data);
    }

    setAfterClearFilterValue(data) {
        this.afterClearFilterValue.next(data);
    }

    setClearFilterValueStaff(data) {
        this.clearFilterValueStaff.next(data);
    }

    setPageNumber(data) {
        this.pageNumber.next(data);
    }

    formatDateOnly(date) {
        let temp = moment(date).utc().format('DD/MM/YYYY');
        return temp
    }

    isValidAmount(str) {
        if (typeof str != "string") {
            this.toastr.error('Please enter valid amount');
            return 0 // we only process strings!  
        }
        this.toastr.error('Please enter valid amount');
        return !isNaN(parseFloat(str)) ? str : 0 // ...and ensure strings of whitespace fail
    }
    paymentGateway(paymentData: PaymentData) {
        const { sessionId = '', amount = '', currency = '', description = '', orderId = '' } = paymentData;
        if (sessionId === '' || amount === '' || currency === '' || description === '' || orderId === '') {
            return null
        }
        Checkout.configure({
            session: {
                id: sessionId
            },
            merchant: environment.MERCHANTID,
            order: {
                amount,
                currency,
                description,
                id: orderId
            },
            interaction: {
                operation: 'PURCHASE',
                merchant: {
                    name: 'Any repair',
                    address: {
                        line1: '200 Sample St',
                        line2: '1234 Example Town'
                    }
                },
                displayControl: {
                    billingAddress: 'HIDE',
                    customerEmail: 'HIDE',
                    orderSummary: 'HIDE',
                    shipping: 'HIDE',
                    paymentConfirmation: 'SHOW'
                }
            }
        });
        Checkout.showLightbox();

    }
    // export to CSV

    generateCSVCustom(tableList, tableColumn, filename = "data") {
        this.options = {
            filename,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: '',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: []
        };
        tableColumn?.forEach((element: any) => {
            this.options.headers.push(element?.displayName)
        });

        const csvExporter = new ExportToCsv(this.options);
        let exportToData = []
        let oneObj = []
        const tableListTobeExported = tableList.map(list => {
            return tableColumn.map((col, i) => {
                var myObj = {}
                myObj[col.Name] = list[col.Name]
                oneObj.push(myObj)
                if ((tableColumn.length - 1) === i) {
                    const output = oneObj.reduce((a, obj) => {
                        const [key, val] = Object.entries(obj)[0];
                        a[key] = val;
                        return a;
                    }, {});
                    exportToData.push(output)
                    oneObj = []
                }
            })
        })
        if (tableList) {
            if (exportToData.length > 0) {
                // this.downloadFile(exportToData, 'test', tableColumn)
                csvExporter.generateCsv(exportToData);
            }
        }
    }

    changeDirection(direction: string) {
        this.direction.next(direction);
    }

    getDirection() {
        return this.direction.asObservable();
    }

}
