import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthService } from './shared/services/auth-service/auth.service';
import { MessagingService } from './shared/services/messaging/messaging.service';
import { SocketioService } from './shared/services/socket/socketio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  onDeactivateSubscription: Subscription
  title = 'app';
  message: any = ''
  currentToken: any; // devicetoken

  constructor(
    private spinner: NgxSpinnerService,
    private socketService: SocketioService,
    private swUpdate: SwUpdate,
    private messagingService: MessagingService,
    private authService: AuthService
  ) {
    const isToken = this.authService.getToken();
    this.messagingService.getCurrentToken().subscribe(res => {

      this.currentToken = res

    })
  }

  ngOnInit() {
  }

  addDeviceDetails() {
    const jwtToken = this.authService.getToken()
    const userDetails = this.authService.getPersonData();
    const payload = {
      language: 'English',
      device: '',
      userType: 'superadmin',
      accessToken: jwtToken,
      fcmTokenKey: this.currentToken,
      lastLoginDate: Date.now(),
      countryId: '610a795f460faaed8387d981',
      timezone: '',
      utcOffset: '',
      userId: userDetails.data._id
    }
    this.authService.addDeviceDetails(payload).subscribe(res => {

    })

  }
}
