import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';

@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    currentToken = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging, private authService: AuthService) { }

    getCurrentToken() {
        return this.angularFireMessaging.getToken;
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.authService.setAlert(false)
                console.log(token);
                this.currentToken.next(token);
                this.addDeviceDetails();
            },
            (err) => {
                this.authService.setAlert(false)
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    getPermission() {
        return this.angularFireMessaging.requestPermission;
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
            })
    }


    addDeviceDetails() {
        const jwtToken = this.authService.getToken()
        const userDetails = this.authService.getPersonData();
        const role = this.getUserType(userDetails.role);

        let currentToken = this.currentToken.getValue();


        const payload = {
            language: 'English',
            device: '',
            userType: role,
            accessToken: jwtToken,
            fcmTokenKey: currentToken,
            lastLoginDate: Date.now(),
            countryId: '610a795f460faaed8387d981',
            timezone: '',
            utcOffset: '',
            userId: userDetails.data._id
        }
        this.authService.addDeviceDetails(payload).subscribe(res => {

        })

    }

    getUserType(role) {
        switch (role) {
            case 1: return 'super-admin';
            case 2: return 'spa';
            case 3: return 'advertiser';
            case 4: return 'module-admin';
            case 5: return 'spa-module-admin';
            default: null
        }
    }
}