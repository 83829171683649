<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left">
  <li class="nav-item d-none d-lg-block">
    <a
      (click)="toggleSidebar.emit()"
      class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)"
    >
      <i class="ti-menu"></i>
    </a>
  </li>

  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle"
      href="javascript:void(0)"
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"></i>
    </a>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="navbarDropdown"
      ngbDropdownMenu
    >
      <span
        class="dropdown-item"
        href="#"
        *ngFor="let lang of languages"
        (click)="changeLanguage(lang)"
      >
        <i class="flag-icon flag-icon-{{ lang.icon }}"></i>
        <span
          >{{ lang.language }}
          <span *ngIf="lang.type">({{ lang.type }})</span>
        </span>
      </span>
    </div>
  </li>

  <!-- ============================================================== -->
  <!-- Search -->
  <!-- ============================================================== -->
  <li class="nav-item search-box">
    <a
      (click)="showSearch = !showSearch"
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)"
    >
      <i class="ti-search"></i>
    </a>
    <form
      class="app-search position-absolute"
      [ngClass]="{ 'show-search': showSearch }"
    >
      <input
        type="text"
        class="form-control"
        placeholder="Search &amp; enter"
      />
      <a class="srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
  </li>
</ul>

<!-- Navbar Header for horizontal only -->
<div class="navbar-header d-none d-lg-block">
  <!-- This is for the sidebar toggle which is visible on mobile only -->
  <a
    (click)="showMobileMenu = !showMobileMenu"
    class="nav-toggler waves-effect waves-light d-block d-lg-none"
    href="javascript:void(0)"
  >
    <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
  </a>
  <!-- ============================================================== -->
  <!-- Logo -->
  <!-- ============================================================== -->
  <a class="navbar-brand" href="/">
    <!-- Logo icon -->
    <b class="logo-icon">
      <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
      <!-- Dark Logo icon -->
      <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
      <!-- Light Logo icon -->
      <img
        src="assets/images/logo-light-icon.png"
        alt="homepage"
        class="light-logo"
      />
    </b>
    <!--End Logo icon -->
    <!-- Logo text -->
    <span class="logo-text">
      <!-- dark Logo text -->
      <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" />
      <!-- Light Logo text -->
      <img
        src="assets/images/logo-light-text.png"
        class="light-logo"
        alt="homepage"
      />
    </span>
  </a>
  <!-- ============================================================== -->
  <!-- End Logo -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Toggle which is visible on mobile only -->
  <!-- ============================================================== -->
  <a
    class="topbartoggler d-block d-lg-none waves-effect waves-light"
    href="javascript:void(0)"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
  >
    <i class="ti-more"></i>
  </a>
</div>

<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle waves-effect waves-dark"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="mdi mdi-message"></i>
      <div class="notify">
        <span class="heartbit"></span> <span class="point"></span>
      </div>
    </a>
    <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
      <ul class="list-style-none">
        <li class="border-bottom">
          <div class="drop-title bg-info text-white">Notifications</div>
        </li>
        <li>
          <div class="message-center notifications" [perfectScrollbar]="config">
            <!-- Message -->
            <a
              href="javascript:void(0)"
              class="message-item"
              *ngFor="let notification of notifications"
            >
              <span class="btn btn-circle {{ notification.btn }}">
                <i class="{{ notification.icon }}"></i>
              </span>
              <span class="mail-contnet">
                <h5 class="message-title">{{ notification.title }}</h5>
                <span class="mail-desc">{{ notification.subject }}</span>
                <span class="time">{{ notification.time }}</span>
              </span>
            </a>
          </div>
        </li>
        <li>
          <a class="nav-link text-center m-b-5" href="javascript:void(0);">
            <strong>Check all notifications</strong>
            <i class="fa fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Messages -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle waves-effect waves-dark"
      href="javascript:void(0)"
      id="2"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="mdi mdi-email"></i>
      <div class="notify">
        <span class="heartbit"></span> <span class="point"></span>
      </div>
    </a>
    <div
      class="dropdown-menu dropdown-menu-right mailbox"
      aria-labelledby="2"
      ngbDropdownMenu
    >
      <ul class="list-style-none">
        <li class="border-bottom">
          <div class="drop-title bg-info text-white">Messages</div>
        </li>
        <li>
          <div class="message-center message-body" [perfectScrollbar]="config">
            <!-- Message -->
            <a
              href="javascript:void(0)"
              class="message-item"
              *ngFor="let mymessage of mymessages"
            >
              <span class="user-img">
                <img
                  src="{{ mymessage.useravatar }}"
                  alt="user"
                  class="rounded-circle"
                />
                <span
                  class="profile-status {{ mymessage.status }} pull-right"
                ></span>
              </span>
              <span class="mail-contnet">
                <h5 class="message-title">{{ mymessage.from }}</h5>
                <span class="mail-desc">{{ mymessage.subject }}</span>
                <span class="time">{{ mymessage.time }}</span>
              </span>
            </a>
          </div>
        </li>
        <li>
          <a class="nav-link text-center link" href="javascript:void(0);">
            <b>See all e-Mails</b>
            <i class="fa fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Messages -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- mega menu -->
  <!-- ============================================================== -->
  <li class="nav-item mega-dropdown" ngbDropdown [autoClose]="false">
    <a
      ngbDropdownToggle
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="mdi mdi-view-grid"></i>
    </a>
    <div class="dropdown-menu" ngbDropdownMenu>
      <div class="mega-dropdown-menu row">
        <div class="col-lg-3 col-xlg-2 m-b-30">
          <h4 class="m-b-20">CAROUSEL</h4>
          <!-- CAROUSEL -->
          <ngb-carousel>
            <ng-template ngbSlide>
              <img
                src="assets/images/big/img1.jpg"
                class="img-fluid"
                alt="Random first slide"
              />
              <div class="carousel-caption">
                <h3 class="text-white font-bold">First slide label</h3>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img
                src="assets/images/big/img2.jpg"
                class="img-fluid"
                alt="Random second slide"
              />
              <div class="carousel-caption">
                <h3 class="text-white font-bold">Second slide label</h3>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img
                src="assets/images/big/img3.jpg"
                class="img-fluid"
                alt="Random third slide"
              />
              <div class="carousel-caption">
                <h3 class="text-white font-bold">Third slide label</h3>
              </div>
            </ng-template>
          </ngb-carousel>
          <!-- End CAROUSEL -->
        </div>
        <div class="col-lg-3 m-b-30">
          <h4 class="m-b-20">ACCORDION</h4>
          <!-- Accordian -->
          <ngb-accordion
            [closeOthers]="true"
            activeIds="static-1"
            class="accordion nav-accordion"
          >
            <ngb-panel id="static-1" title="Simple">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-2">
              <ng-template ngbPanelTitle>
                <span>&#9733; <b>Fancy</b> title &#9733;</span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-3" title="Disabled" [disabled]="true">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div class="col-lg-3 m-b-30">
          <h4 class="m-b-20">CONTACT US</h4>
          <!-- Contact -->
          <form>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="exampleInputname1"
                placeholder="Enter Name"
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                placeholder="Enter email"
              />
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                id="exampleTextarea"
                rows="3"
                placeholder="Message"
              ></textarea>
            </div>
            <button type="submit" class="btn btn-info">Submit</button>
          </form>
        </div>
        <div class="col-lg-3 col-xlg-4 m-b-30">
          <h4 class="m-b-20">List style</h4>
          <!-- List style -->
          <ul class="list-style-none">
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i> You can give link</a
              >
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i> Give link</a
              >
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i> Another Give link</a
              >
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i> Forth link</a
              >
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i> Another fifth link</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End mega menu -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        src="assets/images/users/1.jpg"
        alt="user"
        class="rounded-circle"
        width="31"
      />
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
      <div class="d-flex no-block align-items-center p-15 m-b-10">
        <div class="">
          <img
            src="assets/images/users/1.jpg"
            alt="user"
            class="rounded"
            width="70"
          />
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0 font-15">Steave Jobs</h4>
          <p class="text-muted m-b-0 font-12">varun@gmail.com</p>
          <a
            href="javascript:void(0)"
            class="btn btn-danger btn-rounded btn-sm m-t-5"
            >View Profile</a
          >
        </div>
      </div>
      <a class="dropdown-item" href="javascript:void(0)">
        <i class="ti-user m-r-5 m-l-5"></i> My Profile</a
      >
      <a class="dropdown-item" href="javascript:void(0)">
        <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a
      >
      <a class="dropdown-item" href="javascript:void(0)">
        <i class="ti-email m-r-5 m-l-5"></i> Inbox</a
      >
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void(0)">
        <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a
      >
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void(0)">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a
      >
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
