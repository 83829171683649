import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RouteInfo } from './vertical-sidebar.metadata';
import { serviceProvider, advertiser, superAdminValues } from './vertical-menu-items';
import { AuthService } from '../services/auth-service/auth.service';
@Injectable({
    providedIn: 'root'
})
export class VerticalSidebarService {

    public screenWidth: any;
    public collapseSidebar: boolean = false;
    public fullScreen: boolean = false;

    public MENUITEMS: Array<any>;
    items = new BehaviorSubject<RouteInfo[]>([]);
    menu: any;
    private personType: any;
    sidebarItems: any;
    constructor(
        private authService: AuthService
    ) {
        this.items.next([])
        this.personType = this.authService.getPersonId();
        this.sidebarItems = this.authService.getAccessModules();

        this.menu = this.authService.getAccessModule();
        //  // console.log('menu', this.menu);
        // //  // console.log(this.authService.getAccessModule())
        this.authService._userRole.subscribe(role => {
            this.personType = role;
            this.setVerticalSidebar()
        })
        this.setVerticalSidebar()
        //  // console.log('sidebarItems', this.sidebarItems);

    }
    setVerticalSidebar() {
        if (this.personType == 1) {
            // commonMenu.forEach(item => {
            //     superAdminValues.push(item)
            // })
            // this.MENUITEMS = superAdminValues;
            this.MENUITEMS = this.menu;
            //  // console.log('MENUITEMS', this.MENUITEMS)
            this.items.next(this.MENUITEMS)
        } else if (this.personType == 2) {
            this.MENUITEMS = this.menu;
            //  // console.log('MENUITEMS', this.MENUITEMS)
            // this.MENUITEMS = serviceProvider;
            this.items.next(this.MENUITEMS)
        } else if (this.personType == 3) {
            this.MENUITEMS = advertiser;
            this.items.next(this.MENUITEMS)
        } else if (this.personType == 4) {
            this.MENUITEMS = this.sidebarItems;
            this.items.next(this.MENUITEMS)
        } else {
            this.MENUITEMS = this.menu;
            //  // console.log('MENUITEMS', this.MENUITEMS)
            this.items.next(this.MENUITEMS);
        }

        //  else {
        //     this.MENUITEMS = defaultValues;
        //     this.items.next(this.MENUITEMS)
        // }
    }
}
