import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {


  defaultUrl: string = environment.serverURL;
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService

  ) { }
  sendNotification(data) {
    return this.http.post(`${this.defaultUrl}/admin/send-custom-notification`, data);
  }
  getNotification(data) {
    this.spinner.show()
    return this.http.post(`${this.defaultUrl}/admin/get-super-admin-notification`, data)
      .pipe(map((result: any) => {
        this.spinner.hide()
        // return result;
        return {data:result.data.map(ele => {
          return {
            ...ele,
            created_at: moment(ele.created_at).format(CONSTANTS.DATE_FORMAT + ', ' + CONSTANTS.HOURS_12)
          }
        }),totalCount:result.totalCount}
      }), catchError((err: any) => {
        this.spinner.hide()
        return throwError(err)
      }))

  }

  getNotificationsByUser(pageNumber: any) {
    this.spinner.show()
    return this.http.get(`${this.defaultUrl}/serviceProviderAdmin/allNotificationsList?pageNo=${pageNumber}`)
        .pipe(map((result: any) => {
          this.spinner.hide()
          // return result;
          return { data:result.data.paginatedResults.map(ele => {
              return {
                ...ele,
                createdAt: moment(ele.createdAt).fromNow()
              }
            }),total:result.total, hasNextPage: result.hasNextPage}
        }), catchError((err: any) => {
          this.spinner.hide()
          return throwError(err)
        }))
  }
}
