import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CONSTANTS } from 'src/app/constants';
import { AdminService } from '../../services/admin/admin.service';
import { AuthService } from '../../services/auth-service/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('mobileField', { static: false }) mobileField: ElementRef | null = null
  // company name
  // address 
  // PAN,
  // GST



  passwordEducateString: any = CONSTANTS.PASSWORD_VALIDATION_EDUCATE_STRING;

  editAdminSubscription: Subscription;
  editAdminForm: FormGroup;
  initialIntelObj: { initialCountry: string; nationalMode: boolean };

  fieldTextType: boolean = false;
  role: any;
  countryIso: any;
  userData: any;
  address: any;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private adminService: AdminService,
    private router: Router,
    private authService: AuthService
  ) {
    this.editAdminForm = this.fb.group(
      {
        moduleId: [''],
        numberWithCode: [''],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        mobileNumber: [''],
        email: [''],
        newPassword: ['', [Validators.pattern(CONSTANTS.PASSWORD_VALIDATOR)]],
        city: [''],
        state: [''],
        country: [''],
        countryCode: [''],
        companyName: [''],
        // pan: [''],
        address: [''],
        // gst: [''],
        rolesInformation: [{
          roleId: '',
          roleName: ''
        }],
        existingPassword: [''],
        countryDetails: [{}]
      }

    )
  }

  ngOnInit(): void {

    this.getUserDetails()
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  onSubmit() {
    this.editAdminForm.value.numberWithCode = this.editAdminForm.value.countryCode + '' + this.editAdminForm.value.mobileNumber
    if (this.editAdminForm.value.address == '') {
      this.toastr.error('Company address is required')
      return
    }
    if (this.editAdminForm.valid) {
      this.editAdminSubscription = this.adminService.updateSuperAdmin(this.editAdminForm.value).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.message)
          this.setPersonsLocalStorage({ data: res.data, role: 1 })
          this.getUserDetails()
          this.router.navigate(['/dashboard'])
        }
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toastr.error(err.error.message)
      })
    } else {
      return
      this.spinner.hide()

    }
  }
  setPersonsLocalStorage(data) {
    const setLocalStorage = data;
    this.authService.setPersonData(setLocalStorage)
  }
  getUserDetails() {
    this.userData = this.authService.getPersonData();
    this.role = this.userData.role === 1 ? 'Super admin' : ''
    if (this.userData.role === 2) {
      this.router.navigate([`/service-provider/edit/${this.userData.data._id}`])
    }
    const moduleId = this.userData.data._id
    const {
      numberWithCode,
      firstName,
      lastName,
      mobileNumber,
      email,
      newPassword = '',
      city,
      state,
      country,
      countryCode,
      rolesInformation,
      countryDetails,
      companyName,
      pan: pan = '0',
      address,
      gst: gst = '0'
    } = this.userData.data
    this.countryIso = countryDetails?.iso2
    this.initialIntelObj = {
      initialCountry: this.countryIso,
      nationalMode: false
    }

    const existingPassword = this.userData.data.password
    this.editAdminForm.patchValue(
      {
        moduleId,
        numberWithCode,
        firstName,
        lastName,
        mobileNumber,
        email,
        newPassword,
        city,
        state,
        country,
        countryCode,
        rolesInformation,
        existingPassword,
        countryDetails,
        companyName,
        pan: '0',
        address,
        gst: '0'
      }
    )
    this.address = address

  }

  hasError(event) {
  }
  telInputObject(event) {
    const getnumber: any = document.getElementById('number')

    this.onCountryChange(event.s)

  }

  onCountryChange(event) {
    this.editAdminForm.patchValue({
      countryCode: event.dialCode,
      countryDetails: event
    });
    this.mobileField?.nativeElement?.focus()

  }
  async getAddress(place) {
    // console.log('emitted address===>>>', this.branches, type, index, this.newBranches.length)

    this.editAdminForm.patchValue({
      address: place.address
    })


  }
}
