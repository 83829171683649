import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth-service/auth.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  pageInfo: Data = Object.create(null);
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService
  ) {
    const userType = localStorage.getItem('person-id')
    const accessModuleList = this.authService.getAccessModule()

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.pageInfo = event;

        this.titleService.setTitle(event['title']);

        if (userType === '2') {

          if (this.pageInfo?.urls && this.pageInfo?.title === 'Edit service provider') {
            this.pageInfo.urls = [this.pageInfo?.urls[1]]
          }
        }
        if (userType === '3') {
          this.pageInfo.urls = [this.pageInfo?.urls[1]]
        }
        if (userType === '5') {

          if (this.pageInfo?.urls && this.pageInfo?.urls[1]?.title === 'Edit addmin') {
            this.pageInfo.urls = [this.pageInfo?.urls[1]]
          }
        }
        if (accessModuleList) {
          const isDashboard = accessModuleList.some(ele => ele?.path === 'dashboard')
          if (!isDashboard) {
            if (this.pageInfo?.urls && this.pageInfo?.urls[0]?.url?.includes('dashboard')) {
              this.pageInfo?.urls?.splice(0, 1)
            }
          }
        }
      });
  }
  ngOnInit() { }
}
