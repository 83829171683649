  <div class="main-container">
  <form autocomplete="off" [formGroup]="editAdminForm" #ngForm="ngForm" (ngSubmit)="onSubmit()">
    <div class="toggle-switch"></div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="companyName" class="control-label col-form-label d-flex">First name / الاسم الأول

              <span *ngIf="
                  editAdminForm.controls['firstName'].invalid &&
                  (editAdminForm.controls['firstName'].dirty ||
                    editAdminForm.controls['firstName'].touched)
                " class="alert-custom">
                <span class="form-text text-danger ml-2" *ngIf="editAdminForm.controls['firstName'].errors.required">
                  *
                </span>
              </span>
            </label>
            <input autocomplete="false" type="text" class="form-control" formControlName="firstName" name="firstName"
              placeholder="First name here" required />
            <div>
              <small class="form-text text-danger" *ngIf="
                  !editAdminForm.get('firstName').valid && ngForm.submitted
                ">Please enter a first name!</small>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="name" class="control-label col-form-label d-flex">Last name / اسم العائلة

              <span *ngIf="
                  editAdminForm.controls['lastName'].invalid &&
                  (editAdminForm.controls['lastName'].dirty ||
                    editAdminForm.controls['lastName'].touched)
                " class="alert-custom">
                <span class="form-text text-danger ml-2" *ngIf="editAdminForm.controls['lastName'].errors.required">
                  *
                </span>
              </span>
            </label>
            <input autocomplete="false" type="text" class="form-control" formControlName="lastName" name="lastName"
              placeholder="Last name here" />
            <div>
              <small class="form-text text-danger"
                *ngIf="!editAdminForm.get('lastName').valid && ngForm.submitted">Please enter a last name name!</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <div class="code-no">
              <div class="mobile-no-field">
                <div class="mobile-no-field">
                  <label for="Mobile" class="control-label col-form-label">Mobile number / رقم الهاتف المحمول
                  </label>
                  <div class="mobile-country">
                    <ng-container *ngIf="
                        editAdminForm.controls['mobileNumber'] &&
                        initialIntelObj
                      ">
                      <div class="form-control d-flex">
                        <div class="col-2" *ngIf="initialIntelObj !== undefined">
                          <input [readonly]="userData.data.mobileNumber.length > 0" class="h-100 border-0" ng2TelInput
                            [ng2TelInputOptions]="initialIntelObj" (intlTelInputObject)="telInputObject($event)"
                            (countryChange)="onCountryChange($event)" formControlName="countryCode"
                            onkeydown="return false" />
                        </div>
                        <div class="col-10">
                          <input #mobileField [readonly]="userData.data.mobileNumber.length > 0" type="text"
                            formControlName="mobileNumber" class="w-100 border-0" placeholder="Mobile number"
                            autocomplete="false" [maxlength]="mobileNumberMaxLength" />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="name" class="control-label col-form-label">Email id / عنوان الايميل
            </label>

            <span class="form-control">
              {{ editAdminForm.value.email }}
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="companyName" class="control-label col-form-label d-flex">Company name / اسم الشركة

              <span *ngIf="
                  editAdminForm.controls['companyName'].invalid &&
                  (editAdminForm.controls['companyName'].dirty ||
                    editAdminForm.controls['companyName'].touched)
                " class="alert-custom">
                <span class="form-text text-danger ml-2" *ngIf="editAdminForm.controls['companyName'].errors.required">
                  *
                </span>
              </span>
            </label>
            <input autocomplete="false" type="text" class="form-control" formControlName="companyName"
              name="companyName" placeholder="Company name here" required />
            <div>
              <small class="form-text text-danger" *ngIf="
                  !editAdminForm.get('companyName').valid && ngForm.submitted
                ">Please enter a company name name!</small>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <!-- <input autocomplete="false" type="text" class="form-control" formControlName="address"
                            name="address" placeholder="Last name here"> -->
          <div class="form-group">
            <label for="companyAddress" class="control-label col-form-label d-flex">Company address / عنوان الشركة
            </label>
            <AutocompleteComponent class="form-control text-capitalize" (setAddress)="getAddress($event)"
              [defaultValue]="address" addressType="geocode">
            </AutocompleteComponent>
          </div>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="companyName" class="control-label col-form-label d-flex">Tax ID / الرقم الضريبي
              <span *ngIf="
                  editAdminForm.controls['pan'].invalid &&
                  (editAdminForm.controls['pan'].dirty ||
                    editAdminForm.controls['pan'].touched)
                " class="alert-custom">
                <span class="form-text text-danger ml-2" *ngIf="editAdminForm.controls['pan'].errors.required">
                  *
                </span>
              </span>
            </label>
            <input autocomplete="false" type="text" class="form-control" formControlName="pan" name="pan"
              placeholder="First name here" disabled />
            <div>
              <small class="form-text text-danger" *ngIf="!editAdminForm.get('pan').valid && ngForm.submitted">Please
                enter a pan number!</small>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="name" class="control-label col-form-label d-flex">GST / ضريبة السلع والخدمات

              <span *ngIf="
                  editAdminForm.controls['gst'].invalid &&
                  (editAdminForm.controls['gst'].dirty ||
                    editAdminForm.controls['gst'].touched)
                " class="alert-custom">
                <span class="form-text text-danger ml-2" *ngIf="editAdminForm.controls['gst'].errors.required">
                  *
                </span>
              </span>
            </label>
            <input autocomplete="false" type="text" class="form-control" formControlName="gst" name="gst"
              placeholder="Last name here" disabled />
            <div>
              <small class="form-text text-danger" *ngIf="!editAdminForm.get('gst').valid && ngForm.submitted">Please
                enter a gst number!</small>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="companyName" class="control-label col-form-label">Role / دور</label>

            <span class="form-control">
              {{ role }}
            </span>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="name" class="control-label col-form-label">New password / كلمة المرور الجديدة
            </label>
            <div class="password-field">
              <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="newPassword"
                name="mobileNumber" id="Mobile" placeholder="New password" autocomplete="new-password" />
              <div class="input-group-append" (click)="toggleFieldTextType()">
                <span class="input-group-text">
                  <i class="fa" [ngClass]="{
                      'fa-eye-slash': !fieldTextType,
                      'fa-eye': fieldTextType
                    }"></i>
                </span>
              </div>
            </div>
          </div>
          <div style="color: red" *ngIf="
              editAdminForm.get('newPassword').touched &&
              editAdminForm.get('newPassword').dirty &&
              editAdminForm.get('newPassword').invalid
            ">
            {{ passwordEducateString }}
          </div>
        </div>
        <div class="col-sm-8 col-md-4"></div>
      </div>

      <div class="row form-submit-buttons">
        <div class="col-sm-8 col-md-4"></div>
        <div class="col-sm-8 col-md-4">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <button type="submit" class="btn submit-button btn-block btn-outline-secondary center">
                Save
              </button>
            </div>
            <div class="col-sm-12 col-md-6">
              <button type="button" class="btn btn-block btn-outline-secondary center cancel-button"
                routerLink="/dashboard">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-8 col-md-4"></div>
      </div>
    </div>
  </form>
</div>